<template>
  <div>
    <div
      class="text-detail"
      :style="formattedImageList.length ? 'padding-top: 0 !important' : ''"
      v-if="PodcastDetail && Object.keys(PodcastDetail).length"
    >
      <TopBlock
        v-if="formattedImageList && formattedImageList.length"
        :title="PodcastDetail.name"
        :subtitle="PodcastDetail.subtitle"
        :description="PodcastDetail.description"
        :images="formattedImageList"
      />
      <div class="content">
        <div
          v-if="!formattedImageList.length"
          class="text-detail__title"
          :style="`color: ${PodcastDetail.color}`"
          v-html="PodcastDetail.name"
        ></div>
        <div
          v-if="!formattedImageList.length"
          class="text-detail__sub-title"
          :style="`color: ${PodcastDetail.color}`"
          v-html="PodcastDetail.subtitle"
        ></div>
      </div>
      <Breadcrumbs
        :pages="[
          { name: 'Библиотека', link: { name: 'Library' } },
          {
            name: 'Подкасты',
            link: {
              name: 'Podcasts',
            },
          },
          {
            name: PodcastDetail.name,
          },
        ]"
        :color="'#b2b4b4'"
      />
      <div class="content">
        <div class="text-detail__row mt-8">
          <div class="text-detail__body">
            <div class="material__tags-wrap">
              <div class="material__tags">
                <div class="material__tag" v-if="PodcastDetail.type">
                  {{ PodcastDetail.type.name }}
                </div>
                <div
                  class="material__tag"
                  v-for="nosology in PodcastDetail.nosology"
                  :key="`nos${nosology.id}`"
                  v-html="nosology.title"
                ></div>
                <div
                  class="material__tag"
                  v-for="disease in PodcastDetail.disease"
                  :key="`dis${disease.id}`"
                  v-html="disease.title"
                ></div>
                <div
                  class="material__tag material__tag_new"
                  v-if="PodcastDetail.is_new && false"
                >
                  Новинка
                </div>
              </div>
              <Favorite
                class="large ml-auto ml-xs-0"
                :watchLater="PodcastDetail.watch_later"
                :favorite="PodcastDetail.favorite"
                v-if="!$route.query.access"
                @watchLater="
                  watchLater(
                    PodcastDetail.watch_later ? 'DELETE' : 'POST',
                    PodcastDetail.slug,
                    null,
                    true
                  )
                "
                @favorite="
                  favorite(
                    PodcastDetail.favorite ? 'DELETE' : 'POST',
                    PodcastDetail.slug,
                    null,
                    true
                  )
                "
              />
            </div>
            <div v-html="PodcastDetail.interesting_facts"></div>
            <div
              class="text-detail__subtitle"
              v-if="!formattedImageList.length"
              v-html="PodcastDetail.description"
            ></div>
            <div
              class="text-detail__speakers mb-8"
              v-if="PodcastDetail.hosts && PodcastDetail.hosts.length"
            >
              <div class="text-detail__speakers-header">
                <div
                  class="text-detail__speakers-title"
                  :style="`color: ${PodcastDetail.color}`"
                >
                  Спикеры выпуска
                </div>
              </div>
              <div class="text-detail__speakers-list">
                <div
                  class="text-detail__speaker"
                  v-for="(speaker, ind) in PodcastDetail.hosts"
                  :key="ind"
                >
                  <div class="text-detail__speaker-image-container">
                    <img
                      class="text-detail__speaker-image"
                      :src="speaker.photo"
                    />
                  </div>
                  <div class="text-detail__speaker-tag">Спикер</div>
                  <div class="text-detail__speaker-text">
                    <div class="text-detail__speaker-name">
                      {{ speaker.full_name }}
                    </div>
                    <div
                      class="text-detail__speaker-description"
                      v-html="speaker.position"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <PodcastEpisode
              :ind="ind"
              :background_color="PodcastDetail.background_color"
              :color="PodcastDetail.color"
              v-for="(episode, ind) in PodcastDetail.episodes"
              :arrow="PodcastDetail.episodes.length > 1"
              :episode="episode"
              :key="ind"
              @timingCompleted="timingCompleted"
            />
            <InfoDrop
              v-if="PodcastDetail.sources"
              :active="true"
              :item="{ title: 'Источники', content: PodcastDetail.sources }"
            />
          </div>
          <div class="sticky">
            <RightAside
              :items="asideItems"
              @medicationsClick="medicationsClick"
              @calendarClick="calendarClick"
              @nosologyClick="nosologyClick"
            />
          </div>
        </div>
      </div>
      <LibrarySlider
        v-if="sliderItems.length"
        :nosology="PodcastDetail.nosology[0] || ''"
        :items="sliderItems"
        :video="true"
        :page="'PodcastDetail'"
      />
    </div>
    <div v-else-if="PodcastDetail === 404"><Choch class="inPage" /></div>
    <div v-else-if="PodcastDetail === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import TopBlock from "@/components/pageComponents/TopBlock.vue";

import "@/assets/scss/detail_content.scss";
import RightAside from "../../components/pageComponents/RightAside.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import PodcastEpisode from "@/components/pageComponents/PodcastEpisode.vue";
import InfoDrop from "@/components/pageComponents/InfoDrop.vue";
import Favorite from "../../components/pageComponents/Favorite.vue";

export default {
  metaInfo() {
    if (this.PodcastDetail.name) {
      return { title: this.PodcastDetail.name.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.PodcastDetail.name };
    }
  },
  name: "Podcasts",
  components: {
    Breadcrumbs,
    Choch,
    RightAside,
    LibrarySlider,
    Error,
    PodcastEpisode,
    TopBlock,
    InfoDrop,
    Favorite,
  },
  data: () => ({
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
  }),
  computed: {
    ...mapGetters(["PodcastDetail", "PodcastsList"]),
    sliderItems() {
      return this.PodcastsList
        ? this.PodcastsList.filter((el) => el.slug !== this.PodcastDetail.slug)
        : [];
    },
    filter() {
      if (this.PodcastDetail && Object.keys(this.PodcastDetail).length) {
        return {
          therapeutic_areas: [this.PodcastDetail.nosology[0]?.slug],
        };
      } else {
        return {};
      }
    },
    formattedImageList() {
      let imageList = [];
      let usedImages = Object.keys(this.PodcastDetail)
        .filter((el) => el.includes("banner_"))
        .map((el) => el.replace("banner_", ""));
      for (let imgFormat of usedImages) {
        if (
          this.PodcastDetail["banner_" + imgFormat] &&
          Object.keys(this.media).includes(imgFormat)
        ) {
          imageList.push({
            src:
              this.$root.host +
              this.PodcastDetail["banner_" + imgFormat].replace(
                "http:",
                "https:"
              ),
            media: `(max-width: ${this.media[imgFormat]}px)`,
          });
        }
      }
      imageList.sort((a, b) => (a.media > b.media ? 1 : -1));
      return imageList;
    },
    asideItems() {
      if (this.PodcastDetail && Object.keys(this.PodcastDetail).length) {
        return [
          {
            title: "Препараты АЗ",
            descr: "",
            image: require("@/assets/img/cardio-img/aside-1.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-1_small.jpg"),
            theme: "white",
            event: "medicationsClick",
            to: {
              name: "Medications",
              query: {
                therapeutic_areas: this.PodcastDetail.nosology
                  .map((el) => el.slug)
                  .join(","),
              },
            },
          },
          {
            title: "Календарь мероприятий",
            image: require("@/assets/img/cardio-img/aside-2.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-2_small.jpg"),
            event: "calendarClick",
            to: {
              name: "Events",
              query: {
                therapeutic_areas: this.PodcastDetail.nosology
                  .map((el) => el.slug)
                  .join(","),
              },
            },
          },
          {
            title: "Терапевтические <br>области",
            image: require("@/assets/img/cardio-img/aside-4.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-4_small.jpg"),
            event: "nosologyClick",
            to: { name: "Nosology" },
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchPodcastDetail",
      "fetchPodcasts",
      "fetchPodcastWatchLater",
      "fetchPodcastFavorite",
      "fetchPodcastHistory",
    ]),
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    async timingCompleted(ind, target) {
      if (this.$route.query && this.$route.query.email) {
        await pe.setPerson({
          email: this.$route.query?.email,
        });
      }
      pe.track("Videopodkasty", {
        duration: target.toString(),
        podcastnumber: ind,
        pocastname: this.PodcastDetail.name,
      });
    },
    async watchLater(method, slug, filter, favoritePage) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "podcasts page watch later", {
          "podcasts page watch later": {
            "podcast name": this.PodcastDetail.name,
            ...this.$root.ymFields,
          },
        });
      }
      await this.fetchPodcastWatchLater({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchPodcastDetail({ slug: this.$route.params.slug });
    },
    async favorite(method, slug, filter, favoritePage) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "podcasts page add to favourites", {
          "podcasts page add to favourites": {
            "podcast name": this.PodcastDetail.name,
            ...this.$root.ymFields,
          },
        });
      }
      await this.fetchPodcastFavorite({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchPodcastDetail({ slug: this.$route.params.slug });
    },
    medicationsClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "podcasts page medications click", {
          "podcasts page medications click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    calendarClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "podcasts  page events click", {
          "podcasts  page events click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    nosologyClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "podcasts page therapeutic areas click", {
          "podcasts page therapeutic areas click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
  },
  async mounted() {},
  watch: {
    "$route.params.slug": {
      handler: async function () {
        if (!this.$route.query.access) {
          await this.fetchPodcastDetail({ slug: this.$route.params.slug });
          await this.fetchPodcastHistory(this.$route.params.slug);
        }
      },
      deep: true,
      immediate: true,
    },
    async PodcastDetail() {
      if (
        // !this.PodcastsList.length &&
        this.PodcastDetail &&
        Object.keys(this.PodcastDetail).length
      ) {
        await this.fetchPodcasts(this.filter);
      }
      localStorage.setItem("podcastBlock", this.PodcastDetail.slug);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 136px;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  // &__body {
  //   // margin-top: 32px;
  // }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__sub-title {
    margin-top: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 500;
    line-height: 29px;
  }
  &__subtitle {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    border: 1px solid #d2d2d2;
    border-radius: 32px;
    padding: 24px;
    background: #f8f8f8;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 110px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>

<style lang="scss">
.text-detail {
  &__interesting {
    margin-bottom: 16px;
    background: #f8f8f8;
    border: 1px solid #d2d2d2;
    border-radius: 32px;

    @media screen and (max-width: 767px) {
      padding-bottom: 0 !important;
    }

    &-icon {
      margin-right: 16px;
      flex-shrink: 0;
    }

    &-text {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &-image-container {
      position: relative;
      margin-left: 15px;
      width: 190px;
      flex-shrink: 0;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 15px;
        margin-left: 0;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      object-position: top;
      position: absolute !important;
      top: 0;
      left: 0;

      @media screen and (max-width: 767px) {
        max-height: 180px;
      }

      @media screen and (max-width: 767px) {
        position: static !important;
      }
    }

    &-head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      color: #3ab4d0;
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3c4242;
    }
  }

  &__subtitle {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    border: 1px solid #d2d2d2;
    border-radius: 32px;
    padding: 24px;
    background: #f8f8f8;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__speakers {
    &-title {
      font-family: "Roboto Slab", sans-serif;
      margin-bottom: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #d0006f;
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 52px;

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 32px;
      }
    }
  }

  &__speaker {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    &-tag {
      margin: 16px 0;
      padding: 2px 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      background: #3ab4d0;
      border-radius: 8px;
    }

    &-image-container {
      width: 136px;
      height: 136px;
      flex-shrink: 0;
      margin-right: 12px;
      border-radius: 50%;
      overflow: hidden;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &-name {
      margin-bottom: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #1f1f1f;
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8b8e8e;
    }
  }
}

.sticky {
  @media screen and (min-width: 1221px) {
    position: sticky;
    top: 115px;
  }
}

.material {
  &__tags-wrap {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }
  &__tags {
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1220px) {
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    // margin-bottom: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
    }

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }
}

.favorite {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
}
</style>
